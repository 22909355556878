import { styled } from "linaria/react";
import { colors } from "theme";
export const loaderSize = {
  tiny: "20px",
  small: "32px",
  normal: "45px",
  large: "70px",
};

export const loaderWidth = {
  tiny: "2px",
  small: "2px",
  normal: "3px",
};

export const Container = styled.div<any>`
  width: ${({ size }) => loaderSize[size]};
  height: ${({ size }) => loaderSize[size]};
  border-radius: 100%;
  border: ${({ size }) => loaderWidth[size]} solid rgba(6, 103, 208, 0.05);
  border-top-color: ${({ inverted }) =>
    inverted ? colors.keyLimePie : colors.apple};
  animation: spin 1s linear infinite;

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }
`;
