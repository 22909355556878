import Button, { ButtonProps as ButtonProps } from "components/Button";
import useMemoizedChildren from "hooks/useMemoizedChildren";
import { css } from "linaria";
import React, { FC } from "react";
import CoinbaseCommerceButton from "react-coinbase-commerce";
import "react-coinbase-commerce/dist/coinbase-commerce-button.css";
import { COINBASE_COMMERCE_ID } from "src/config";

const CoinbaseTrigger: FC<ButtonProps> = ({ children, as, ...rest }) => {
  const memoizedChildren = useMemoizedChildren(children);
  return as ? (
    <CoinbaseCommerceButton
      as={as}
      className={reset}
      checkoutId={COINBASE_COMMERCE_ID}
    >
      {memoizedChildren}
    </CoinbaseCommerceButton>
  ) : (
    <Button
      size="medium"
      className={button}
      as={CoinbaseCommerceButton}
      checkoutId={COINBASE_COMMERCE_ID}
      {...rest}
    >
      {memoizedChildren ?? "Give now"}
    </Button>
  );
};

const button = css`
  padding: 0;
`;
const reset = css`
  cursor: pointer;
  padding: 0;
  font-size: unset;
  color: unset;
  margin: unset;
  background: unset;
  border: unset;
`;

export default CoinbaseTrigger;
