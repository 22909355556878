// Ensre no HTML / CSS XSS attack
const sanitizeCss = (value: any): string => {
  if (value || value === 0) {
    return value.toString().replace(/[^#a-z0-9%()., _-]/gi, "");
  }

  return "";
};

export default sanitizeCss;
