import useMemoizedChildren from "hooks/useMemoizedChildren";
import React, { CSSProperties, FC } from "react";
import SuperEllipse, { Preset } from "./SuperEllipse";

type Props = {
  r1?: number | undefined;
  r2?: number | undefined;
  style?: CSSProperties;
  nature?: "default" | "custom";
  className?: string | undefined;
};
const Squircle: FC<Props> = ({
  children,
  r1,
  r2,
  nature = "custom",
  style,
  className,
}) => {
  const memoizedChildren = useMemoizedChildren(children);

  const options = {
    default: {
      p1: 8,
      p2: 40,
    },
    custom: {
      r1: r1 || Preset.iOS.r1,
      r2: r2 || Preset.iOS.r2,
    },
  };

  return (
    <SuperEllipse {...options[nature]} style={style} className={className}>
      {memoizedChildren}
    </SuperEllipse>
  );
};

export default Squircle;
