import { styled } from "linaria/react";
import Flex from "components/Flex";
import Loader from "components/Loader";
import Squircle from "components/Squircle";
import React, {
  ButtonHTMLAttributes,
  DetailedHTMLProps,
  FC,
  ReactNode,
} from "react";
import { colors, FontScale, SpaceScale } from "theme";
import useMemoizedChildren from "hooks/useMemoizedChildren";

export type ElProps = DetailedHTMLProps<
  ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;
type ButtonNature =
  | "light"
  | "default"
  | "charcoal"
  | "disabled"
  | "navy"
  | "blue"
  | "warn";
export type ButtonProps = ElProps & {
  nature?: ButtonNature;
  size?: "small" | "medium" | "default";
  wide?: boolean;
  inverted?: boolean;
  fillSpace?: boolean;
  shadow?: boolean;
  loading?: boolean;
  icon?: ReactNode;
  iconSize?: number;
  as?: any;
  checkoutId?: string;
};
const Button: FC<ButtonProps> = ({
  children,
  wide,
  shadow = false,
  inverted = false,
  nature = "default",
  size = "default",
  type = "button",
  loading,
  icon,
  iconSize = 20,
  ...rest
}) => {
  const memoizedChildren = useMemoizedChildren(children);

  return (
    <StyledButton
      size={size}
      wide={wide}
      nature={nature}
      type={type}
      inverted={inverted}
      shadow={shadow}
      {...rest}
    >
      <Squircle
        style={{
          ...sizeStyles[size],
          ...natureStyles(inverted)[rest.disabled ? "disabled" : nature],
        }}
      >
        <Flex align="center" justify="center">
          <Accessory
            show={!wide}
            nature={nature}
            icon={icon}
            iconSize={iconSize}
            loading={loading}
          />
          <Content wide={wide} key="content">
            {memoizedChildren}
          </Content>
          <Accessory
            show={wide}
            nature={nature}
            icon={icon}
            iconSize={iconSize}
            loading={loading}
            right
          />
        </Flex>
      </Squircle>
    </StyledButton>
  );
};

const Content = styled.div<any>`
  width: 100%;
`;
const Accessory = ({
  loading = false,
  icon,
  right = false,
  nature,
  show,
  iconSize,
}) => {
  if (!show) return null;

  return (
    <>
      {loading && (
        <div
          style={{
            margin: 0,
            lineHeight: 0,
            [right ? "marginLeft" : "marginRight"]: SpaceScale(3),
          }}
          key="icon-or-loader"
        >
          <Loader size="tiny" inverted={["wide", "default"].includes(nature)} />
        </div>
      )}
      {!loading && icon && (
        <div
          style={{
            margin: 0,
            lineHeight: 0,
            [right ? "marginLeft" : "marginRight"]: SpaceScale(3),
          }}
          key="icon-or-loader"
        >
          <Icon Component={icon} iconSize={iconSize} />
        </div>
      )}
    </>
  );
};

const natureStyles = (inverted = false) => ({
  light: {
    background: inverted ? colors.ivy : colors.paper,
    color: inverted ? colors.paper : colors.ivy,
  },
  warn: {
    background: inverted ? colors.white : colors.tomato,
    color: inverted ? colors.tomato : colors.white,
  },
  disabled: {
    background: colors.keyLimePie,
    color: colors.ivy,
  },
  charcoal: {
    background: inverted ? colors.paper : colors.charcoal,
    color: inverted ? colors.charcoal : colors.paper,
  },
  navy: {
    background: inverted ? colors.paper : colors.navy,
    color: inverted ? colors.olympicSkaterSpandex : colors.white,
  },
  blue: {
    background: inverted ? colors.paper : colors.blue,
    color: inverted ? colors.blue : colors.white,
  },
  default: {
    background: inverted ? colors.white : colors.apple,
    color: inverted ? colors.apple : colors.white,
  },
  wide: {
    background: inverted ? colors.white : colors.apple,
    color: inverted ? colors.apple : colors.white,
  },
});
const sizeStyles = {
  small: {
    padding: `${SpaceScale(3)} ${SpaceScale(3)}`,
    fontSize: FontScale(3.5),
  },
  medium: {
    padding: `${SpaceScale(4)} ${SpaceScale(4.25)}`,
    fontSize: FontScale(4),
  },
  default: {
    padding: `${SpaceScale(4.125)} ${SpaceScale(5)}`,
    fontSize: FontScale(4.5),
  },
};

const Icon = ({ Component, iconSize = 20 }) => {
  return Component ? <Component width={iconSize} height={iconSize} /> : null;
};

const StyledButton = styled.button<ButtonProps>`
  filter: ${({ shadow }) =>
    shadow ? "drop-shadow(-10px -2px 20px rgba(0, 0, 0, 0.25))" : "none"};
  text-align: ${(p) => (p.wide ? "left" : "center")};
  font-weight: 600;
  font-size: ${(p) => (p.size === "default" ? FontScale(4.5) : FontScale(3.5))};
  width: ${(p) =>
    p.fillSpace && p.size === "default" ? "-webkit-fill-available" : "unset"};
  border: unset;
  background: unset;
  position: relative;
  padding: 0;
  margin: 0;
  cursor: pointer;

  &:disabled {
    pointer-events: none;
  }

  &:focus {
    outline: none;
  }

  a {
    color: unset;
  }

  /* ${({ icon }) =>
    !icon &&
    `
      svg {
        path {
          stroke: ${({ nature }) =>
            nature === "light" ? colors.ivy : "initial"};
        }
      }
  `} */
`;

export default Button;
