import { ButtonProps as ButtonProps } from "components/Button";
import useMemoizedChildren from "hooks/useMemoizedChildren";
import React, { FC } from "react";
import CoinbaseTrigger from "./CoinbaseTrigger";
import ModalTrigger from "./ModalTrigger";

type Props = { kind: "coinbase" | "zec" | "xrp" } & ButtonProps;
const GiveTrigger: FC<Props> = ({ kind, children, ...rest }) => {
  const memoizedChildren = useMemoizedChildren(children);

  switch (kind) {
    case "coinbase":
      return <CoinbaseTrigger {...rest}>{memoizedChildren}</CoinbaseTrigger>;
    default:
      return <span>Please implement {kind} modal</span>;
  }
};

export default GiveTrigger;
