import { styled } from "linaria/react";
import sanitizeCss from "utils/sanitizeCss";

type Props = {
  justify?:
    | "space-between"
    | "space-evenly"
    | "center"
    | "flex-start"
    | "stretch";
  align?: "center" | "flex-start" | "baseline";
  center?: boolean;
  wide?: boolean;
  column?: boolean;
  $wrap?: boolean;
  $reverse?: boolean;
};
const Flex = styled.div<Props>`
  display: flex;
  width: ${({ wide }) => (wide ? "100%" : "initial")};
  flex-wrap: ${({ $wrap }) => ($wrap ? "wrap" : "nowrap")};
  flex-direction: ${({ column, $reverse }) => {
    const kind = column ? "column" : "row";

    return $reverse ? `${kind}-reverse` : kind;
  }};
  align-items: ${({ align, center }) => {
    if (Boolean(center)) return "center";

    return sanitizeCss(align) ?? "initial";
  }};
  justify-content: ${({ justify, center }) => {
    if (Boolean(center)) return "center";

    return sanitizeCss(justify) ?? "initial";
  }};
`;

export default Flex;
