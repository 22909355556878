import React, { FC } from "react";

import { Container } from "./styles";

export type LoaderSize = "small" | "normal" | "tiny" | "large";
type Props = { inverted?: boolean; size?: LoaderSize };
const Loader: FC<Props> = ({ size = "normal", inverted, ...rest }) => {
  return <Container {...rest} size={size} inverted={inverted} />;
};

export default Loader;
